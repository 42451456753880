import React from "react";
import { ARTICLE_5 } from "shared/Types/articles";
import { useArticlesData } from "shared/Hooks/Articles/useArticlesData";

import { Image, Hero, Layout, Text } from "components/BlogLayout";

const Article_5 = () => {
  const data = useArticlesData(ARTICLE_5);
  return (
    <Layout>
      <Hero data={data} />
      <Text data={data.text.first} />
      <Image data={data.images.first} />
      <Text data={data.text.second} />
      <Text data={data.text.third} />
      <Text data={data.text.fourth} />
      <Text data={data.text.fifth} />
      <Image data={data.images.second} />

      <Text data={data.text.sixth} />
      <Text data={data.text.seventh} />
      <Image data={data.images.third} />

      <Text data={data.text.eigth} />
      <Text data={data.text.ninth} />
      <Text data={data.text.tenth} />
      <Text data={data.text.eleventh} />
      <Text data={data.text.twelfth} />
    </Layout>
  );
};

export default Article_5;
